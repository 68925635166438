.authRoot{
    padding-top: 74px;
    padding-bottom: 84px;
}
.signForm{
    width: 380px;
    margin: 0 auto;
    h1{
        font-size: 22px;
        text-align: center;
        font-weight: 600;
        margin-bottom: 32px;
    }
    .signFormContent{
        margin-bottom: 28px;
        .formItems{
            margin-top: 14px;
            span{
                font-size: 14px;
            }
            .textInput{
                width: 100%;
                height: 49px;
                border-radius: 10px;
                border: 1px solid #DDDDDD;
                padding: 14px 20px 15px 20px;
                box-sizing: border-box;
                margin-top: 8px;
                outline: none;
            }
            .checkbox {
                padding-top: 5px;
                appearance: none;
                outline: none;
                width: 16px;
                height: 16px;
                appearance: none;
                outline: none;
                position: relative;
                cursor: pointer;
                margin-right: 10px;
              }
              
              .checkbox::before {
                display: block;
                position: absolute;
                content: '';
              
                width: 16px;
                height: 16px;
                background: #fff;
                border: solid 1px #DDDDDD;
                border-radius: 4px;
              }
              
              .checkbox:checked::before {
                background-color: #2182c3;
                border: none;
              }
              
              .checkbox::after {
                padding-top: 8px;
                display: block;

                content: '';
                position: absolute;
                left: 5px;
                top: 6px;
              
                width: 4px;
                height: 2px;
                border-right: 2px solid #fff;
                border-bottom: 2px solid #fff;
                transform: rotate(45deg);
                opacity: 0;
              }
              
              .checkbox:checked::after {
                opacity: 1;
              }
            label{
                font-size: 13px;
                a{
                    color: #2D6798;
                }
            }
        }
        .formButton{
            margin-top: 28px;
            width: 100%;
            height: 50px;
            border-radius: 10px;
            border: none;
            color: white;
            font-size: 14px;
            font-weight: 600;
            background-color: #2182c3;
        }
        .forgetpassword{
            margin-top: 14px;
            text-align: right;
            a{
                color: #2D6798;
                font-size: 12px;
            }
        }

    }
    hr{
        background-color: #DDDDDD;
        height: 1px;
        border: none;
    }
    .signFormFooter{
        margin-top: 28px;
        text-align: center;
        font-size: 13px;
        color: #707070;
        a{
            color: #2D6798;
        }
    }
}
.forgetpasswordForm{
    width: 480px;
    margin: 0 auto;
    .forgetFormHeader{
        font-size: 22px;
        text-align: left;
        font-weight: 600;
        margin-bottom: 32px;
    }
    .forgetDescription{
        font-size: 14px;
        padding-bottom: 14px;
        font-weight: 400;
    }
    .forgetFormContent{
        margin-bottom: 28px;
        .formItems{
            margin-top: 14px;
            span{
                font-size: 14px;
            }
            .textInput{
                width: 100%;
                height: 49px;
                border-radius: 10px;
                border: 1px solid #DDDDDD;
                padding: 14px 20px 15px 20px;
                box-sizing: border-box;
                margin-top: 8px;
                outline: none;
            }
        }
        .formButton{
            margin-top: 28px;
            width: 100%;
            height: 50px;
            border-radius: 10px;
            border: none;
            color: white;
            font-size: 14px;
            font-weight: 600;
            background-color: #2182c3;
        }
        .formButtonHalf{
            margin-top: 28px;
            width: 166px;
            height: 50px;
            border-radius: 10px;
            border: none;
            color: white;
            font-size: 14px;
            font-weight: 600;
            background-color: #2182c3;
        }

    }
    hr{
        background-color: #DDDDDD;
        height: 1px;
        border: none;
    }
    .forgetFormFooter{
        margin-top: 28px;
        text-align: left;
        font-size: 13px;
        color: #707070;
        a{
            color: #2D6798;
        }
    }
}

@media screen and (max-width:380px) {
    .signForm{
        width: 92vw;
    }
    .forgetpasswordForm{
        width: 92vw;
    }
}