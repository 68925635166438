@use "../../../style/variables.scss" as variables;

.TalentList {
  @include variables.mq(sm) {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  @include variables.mq(md) {
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
  }
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  grid-auto-rows: minmax(100px, auto);
  padding: 10px;
}