.CardTalent {
  background-color: #2182c3;
  border-radius: 16px;
  color: #ffffff;
  font-weight: bold;
}
.img {
  width: 100%;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.CardTalentButton {
  gap: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50px;
  .CardTalentname{
    margin: 0;
    font-size: 18px;
    padding: 0;
  }
  .CardTalentyomi{
    margin: 0;
    font-size: 12px;
    padding: 0;
  }
}
