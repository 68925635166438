.categoryInput{
    display: flex;
    select{
        width: 100%;
        height: 40px;
        background-color:white;
        border-radius: 5px 0px 0px 5px;
        border: #2082c2 2px solid;
    }
    button{
        background-color: #2082c2;
        border: none;
        color: white;
        border-radius: 0px 5px 5px 0px;
        width: 40px;
        height: 40px;
    }
}
.imageField{
    width: 100%;
    height: 300px;
    border: 1px solid gray;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    .icon{
        font-size: 30px;
        text-align: center;
    }
    p{
        font-size: 16px;
        text-align: center;
    }
}
.textInput{
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #2082c2;
    padding-left: 5px;
    
}
.buttonWapper{
    Button{
        margin-right: 10px;
    }
}
.imageWapper{
    display: flex;
    justify-content: space-between;
    padding-top: 24px;
    img{
        width: 48%;
    }
}
.modalFooter{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.addForm{
    input{
        width: 250px;
        height: 40px;
        border-radius: 5px;
        margin-right: 10px;
        border: 2px solid #2082c2;
        outline: none;
        padding-left: 5px;
    }
}