.TalentInfoTable {
}
.table {
  width: 100%;
  tr {
    td {
      padding: 8px;
    }
    border-bottom: 1px solid #c7c2c2;
  }
  font-weight: 18px;
}
.title {
  font-weight: bold;
}
