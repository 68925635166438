.button {
  height: 44px;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
}
.button.grey {
  background-color: #dddddd;
}
.button.dark {
  background-color: #2182C3;
  color: white;
}
.button.purple {
  background-color: #2182C3;
  color: white;
}
.button.red{
  background-color: #C32121;
  color: white;
}
.tag {
  padding: 10px 16px;
  font-size: 15px;
  font-weight: 600;
  border-radius: 5px;
  box-sizing: border-box;
  width: fit-content;
}
.tag.red {
  background-color: #f7e8f8;
  color: #b842c7;
}
.tag.blue {
  background-color: #e8f2f8;
  color: #4294c7;
}

.tag-small {
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  width: fit-content;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  padding: 0 20px;
  align-items: center;
}
.tag-small.red{
  color: #e03b3b;
  background-color: #fbe9e9;
}
.tag-small.blue{
  background-color: #e8f2f8;
  color: #4294c7;
}

.backLink {
  margin-bottom: 20px;
  a {
    color: #2d6798;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
  }
}

.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  h1 {
    font-size: 22px;
    font-weight: 900;
  }
}

.urltag {
  height: 40px;
  display: flex;
  align-items: center;
  .left {
    width: 159px;
    padding: 0 20px;
    background-color: #f5f5f5;
    height: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-radius: 5px 0 0 5px;
    div {
        width: 100%;
      font-size: 14px;
      font-weight: 400;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .right {
    width: 36px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 5px 5px 0;
  }
  .purple{
    background-color: #AB4DB7;
  }
  .green{
    background-color: #2D6798;
  }
}

.fileupload{
  width: 240px;
  height: 160px;
  border-radius: 5px;
  border:1px #DDDDDD dashed;
  display: flex;
  align-items: center;
  justify-content: center;
  div{
    text-align: center;
  }
  p{
    font-size: 14px;
    font-weight: 400;
    color: #707070;
  }
}
.fileuploadimage{
  width: 240px;
  img{
    width: 100%;
    margin-bottom: 20px;
  }
}