@use "./variables" as variable;

.TalentInfoCard {
  // border: 3px #2182c3 solid;
  width: 100%;

  border-radius: 16px;
  max-width: 1024px;
  margin: 0 auto;
  background-color: white;
}

.head {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: #2182c3;
  color: #ffffff;
  text-align: center;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  h1 {
    font-size: 38px;
    font-weight: 600;
  }

  h2 {
    font-size: 18px;
    font-weight: 600;
  }
}
.box {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 10px;
}
.outline {
  border: 3px #2182c3 solid;
  border-radius: 0 0 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px 10px;
  @include variable.mq(xl) {
    padding: 60px 30px;
  }
}
.content {
  @include variable.mq(md) {
    flex-direction: row;
  }
  display: flex;
  gap: 30px;
  flex-direction: column;
}

.img_box {
  @include variable.mq(md) {
    max-width: 400px;
  }
}

.TalentImg {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.name {
  font-size: 28px;
  font-weight: 800;
}

.yomi {
  font-size: 18px;
  font-weight: 800;
  color: #969696;
}
.profile {
  display: flex;
  flex-direction: column;
  gap: 8px;

  h1 {
    font-size: 24px;
    font-weight: bold;
  }
  margin-bottom: 0;
}
.profileBody {
  line-height: 28px;
  white-space: pre-wrap;
  word-wrap: break-word;

  font-size: 18px;
}
.snsicons {
  display: flex;
  gap: 8px;
}
.snsicon {
  background-color: #e3af00;
  svg {
    width: 1.4em;
    height: 1.4em;
  }
  padding: 8px;
  border-radius: 100%;
  color: #fff;
}
.title {
  display: flex;
  flex-wrap: wrap;
  div {
    margin-right: 10px;
    margin-top: 10px;
  }
}
.titleTag {
  border-radius: 4px;
  font-weight: bold;
  background-color: #fef6db;
  padding: 8px 16px;
  color: #e3af00;
}

.buttonWapper {
  margin: auto;
  height: 64px;
  @include variable.mq(xl) {
    width: 566px;
  }
}
.buttonWapperWapper {
  width: 100%;
  margin-top: 40px;
}
