.talentFormCard {
  width: 100%;
  min-height: 70vh;
  background-color: white;
  border: 2px solid #2082c2;
  padding: 24px;
  box-sizing: border-box;
  overflow-y: hidden;
  .imageHeader{
    text-align: right;
  }
  .profileWapper{
    display: flex;
    padding-top: 12px;
    .profileLeft{
        width: 300px;
        height: 10px;
        img{
          width: 300px;
        }
        .imageUpload{
          width: 300px;
          height: 400px;
          border: 2px solid #2082c2;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 20px;
          text-align: center;
        }
    }
    .profileRight{
      width: 100%;
      padding: 0 24px;
      .formItem{
        margin-bottom: 24px;
      }
      .textInput{
        width: 100%;
        height: 40px;
        border: #2082c2 2px solid;
        border-radius: 5px;
        font-size: 12px;
        padding-left: 10px;
        outline: 0;
      }
      .categoryButtonWapper{
        p{
          font-size: 14px;
        }
        .categoryButton{
          width: 200px;
          height: 40px;
          background-color: #FEF6DB;
          color: #E3AF00;
          border: none;
          border-radius: 5px;
          font-weight: 600;
        }
        .categoryTagWapper{
          display: flex;
          .categoryTag{
            font-size: 12px;
            padding: 5px 12px;
            background-color: #FEF6DB;
            color: #E3AF00;
            margin-right: 10px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            span{
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 5px;
              font-size: 12px;
            }
          }
        }
      }
      .snsWapper{
        margin-top: 24px;
        .addButton{
          width: 35px;
          height: 35px;
          background-color: #2082c2;
          color: white;
          border: none;
          border-radius: 5px 5px 5px 5px;
          font-size: 24px;
          margin-right: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .snsInput{
          margin-bottom: 12px;
          width: 100%;
          display: flex;
          align-items: center;
          button{
            width: 40px;
            height: 40px;
            border-radius: 5px 0px 0px 5px;
            border: none;
            background-color: #2082c2;
            color: white;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          input{
            height: 40px;
            box-sizing: border-box;
            width: 100%;
            border-radius: 0px 0px 0px 0px;
            border: 2px solid #2082c2;
            padding-left: 10px;
            outline: 0;
          }
          .CloseButton{
            width: 40px;
            height: 40px;
            background-color: #2082c2;
            color: white;
            border: none;
            border-radius: 0px 5px 5px 0px;
            font-size: 24px;
            margin-right: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .talentInfoTable{
        margin-top: 20px;
        .talentTableRow{
          display: flex;
          align-items: center;
          border-bottom: 1px solid #C7C2C2;
          height: 60px;
          .tag{
            font-size: 12px;
            font-weight: 500;
            width: 80px;
            background-color: #2082c2;
            padding: 4px;
            color: white;
            margin: 0 6px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            .span2{
              margin-left: 5px;
            }
          }
          .talentTableRowLeft{
            width: 200px;
            display: flex;
            align-items: center;
          }
          .talentTableRowRight{
            display: flex;
            align-items: center;
            .talentTableInput{
              width: 200px;
              height: 20px;
              background-color: #2082c2;
              border: none;
              border-radius: 10px;
              color: white;
              padding-left: 10px;
              height: 30px;
            }
            .talentTableTextInput{
              width: 100px;
              margin-right: 20px;
              border-radius: 5px;
              border: 2px solid #2082c2;
              padding-left: 5px;
              height: 30px;
            }
          }
          .nextIcon{
            margin-right: 12px;
          }
          .text{
            margin-right: 12px;
            font-weight: 600;
          }
        }
      }
      .profileText{
        margin-top: 24px;
        .profileTextArea{
          width: 100%;
          resize: vertical;
          border: 2px solid #2082c2;
          height: 150px;
          font-weight: 400;
          padding: 12px;
          box-sizing: border-box;
          outline: 0;
        }
      }
    }
  }
}

.header-buttons{
  Button{
    margin-left: 10px;
  }
}
.images{
  img{
    width: 250px;
    margin: 10px;
  }
}
.UserTable{
  span{
    width: 50%;
  }
}