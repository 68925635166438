.modal-root {
  position: relative;
  .modal-background {
    background-color: rgba(0, 0, 0, 0.4);
    content: "";
    width: 100vw;
    min-height: 100%;
    z-index: 2;
    position: absolute;
    left: 0;
    top: 0;
  }
  .modal-root {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .modal {
      background-color: white;
      padding: 33px 36px;
      width: 640px;
      background-color: white;
      box-sizing: border-box;
      border-radius: 5px;
      box-sizing: border-box;
      .modal-navbar{
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #E5E5E5;
        padding-bottom: 22px;
        span{
            font-size: 16px;
            font-weight: 600;
            margin: 0;
            padding: 0;
        }
        button{
            border: none;
            background-color: white;
            width: 12px;
            height: 12px;
            margin: 0;
            padding: 0;
            padding-top: 6px;
        }
      }
      .formItem{
        margin-top: 24px;
        p{
            margin-bottom: 14px;
            font-size: 14px;
            font-weight: 500;
        }
        .textInput{
            width: 100%;
            height: 49px;
            border-radius: 5px;
            border: 1px solid #DDDDDD;
            padding: 14px 20px 15px 20px;
            box-sizing: border-box;
            outline: none;
        }
        
        .selectInput{
            width: 100%;
            height: 49px;
            border-radius: 5px;
            border: 1px solid #DDDDDD;
            padding: 14px 20px 15px 20px;
            box-sizing: border-box;
            outline: none;
            background-color: white;
        }
    }
    }
  }
}
