@use "sass:map"; //ビルトインモジュールの読み込み。後述。
$breakpoints: (
  "sm": "screen and (min-width: 576px)",
  "md": "screen and (min-width: 768px)",
  "lg": "screen and (min-width: 992px)",
  "xl": "screen and (min-width: 1200px)",
) !default;

//読み込み元ファイル。ここにmixinしたら便利そうなものを書いていく。
@mixin mq($breakpoint) {
  @media #{map-get($breakpoints, $breakpoint)} {
    // ※１
    @content; // ※２
  }
}