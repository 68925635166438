.navbar-top {
  box-sizing: border-box;
  width: 100vw;
  height: 90px;
  padding: 36px 5vw 32px 5vw;
  border-bottom: 1px solid #dddddd;
  .logo {
    height: 22px;
    img {
      height: 22px;
    }
  }
}

.footer {
  width: 100vw;
  box-sizing: border-box;
  height: 64px;
  padding: 0 5vw;
  background-color: #292929;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .copyright {
    color: white;
    font-size: 13px;
  }
  .footer-left {
    a {
      color: white;
      text-decoration: none;
      font-size: 13px;
      margin-right: 20px;
    }
  }
}

.letfNavProvider {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  background-color: #f5f5f5;
  .navbarLeft {
    min-height: 100vh;
    box-sizing: border-box;
    background-color: white;
    width: 320px;
    padding: 36px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    .logo {
      margin-bottom: 30px;
    }
    hr {
      height: 1px;
      border: none;
      background-color: #dddddd;
    }
    .centerItems {
      margin-top: 20px;
      box-sizing: border-box;
      .navbarButton {
        box-sizing: border-box;
        width: 100%;
        height: 45px;
        border: none;
        background-color: white;
        font-size: 13px;
        font-weight: 500;
        display: flex;
        align-items: center;
        padding-left: 16px;
        border-radius: 5px;
        .icon {
          margin-right: 15px;
        }
      }
      .buttonSelected {
        background-color: #2182C3;
        color: white;
        .icon {
          fill: white;
        }
      }
    }
    .footerItem {
      width: 100%;
      box-sizing: border-box;
      .navbarButton {
        box-sizing: border-box;
        width: 100%;
        height: 45px;
        border: none;
        background-color: white;
        font-size: 13px;
        font-weight: 500;
        display: flex;
        align-items: center;
        padding-left: 16px;
        border-radius: 5px;
        color: #1c0b3b;
        .icon {
          margin-right: 15px;
          height: 14px;
          width: 14px;
        }
      }
    }
  }

  .leftNavContent {
    width: 100%;
    height: 100vh;
    padding: 60px;
    box-sizing: border-box;
    overflow: scroll;
  }
}
