.SearchBox {
}
.box {
  display: flex;
  gap: 0;
  margin: auto;
  max-width: 500px;
  margin-bottom: 10px;
  padding: 10px;
  margin-top: 10px;
  input {
    flex: 1;
    &::placeholder {
      color: #b1acac;
    }
    &:focus {
      outline: none;
    }
    width: 100%;
    padding: 8px 18px;
    background-color: white;
    border-radius: 5px 0px 0px 5px;
    border: 2px solid #2182c3;
    font-size: 14px;
    font-weight: 500;
  }
  button {
    &:hover {
      filter: brightness(0.9);
    }
    cursor: pointer;
    font-weight: 600;

    background-color: #2182c3;
    color: white;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 0px 5px 5px 0px;
    .icon{
      margin-right: 5px;
    }
  }
}
